// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getSchoolAdminLessonsStudentsDetailClassesMarks = createAsyncThunk('schoolAdmin/lessons/students/detail/classes/marks', async (payload, { dispatch, getState }) => {
  try {
  
  const response = await useApi.schoolAdminLessonsStudentsDetailClassesMarks(
    payload.userId,
    payload.classId,
    getState().schoolAdminLessonsStudentsDetailClassesMarks.params[payload.classId] ? getState().schoolAdminLessonsStudentsDetailClassesMarks.params[payload.classId] : {
      itemsPerPage: 10,
      currentPage: 1,
      q: ''
    }
  )

  return {
    params: getState().schoolAdminLessonsStudentsDetailClassesMarks.params[payload.classId] ? getState().schoolAdminLessonsStudentsDetailClassesMarks.params[payload.classId] : {
      itemsPerPage: 10,
      currentPage: 1,
      q: ''
    },
    classId: payload.classId,
    ...response.data
  }

} catch (e) {
  console.log(e)
}
})

export const schoolAdminLessonsStudentsDetailClassesMarksSlice = createSlice({
  name: 'schoolAdminLessonsStudentsDetailClassesMarks',
  initialState: {
    isLoading: {},
    data: {},
    total: {},
    mediumMark: {},
    params: {},
  },
  reducers: {
    changeParams(state, action) {
      state.params[action.payload.classId] = {
        ...(state.params[classId] ? state.params[classId] : {}),
        ...action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSchoolAdminLessonsStudentsDetailClassesMarks.pending, (state, action) => {
      //state.isLoading[action.payload.classId] = true
    })
    builder.addCase(getSchoolAdminLessonsStudentsDetailClassesMarks.fulfilled, (state, action) => {
      state.params[action.payload.classId] =  action.payload.params
      state.data[action.payload.classId] = action.payload.data.items
      state.mediumMark[action.payload.classId] = action.payload.data.mediumMark
      state.total[action.payload.classId] = action.payload._meta.pagination.total
      state.isLoading[action.payload.classId] = false
    })
    
  }
})

export const { changeParams } = schoolAdminLessonsStudentsDetailClassesMarksSlice.actions

export default schoolAdminLessonsStudentsDetailClassesMarksSlice.reducer