// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('admin/schools/getData', async params => {
  const response = await useApi.adminGetSchools(params)

  return {
    params,
    data: response.data.data,
    totalPages: response.data['_meta']['pagination']['total'] || 0
  }
})

export const getUser = createAsyncThunk('admin/schools/getUser', async id => {
  const response = await useApi.adminGetSchool(id)
  return response.data.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  return id
})

export const appUsersSlice = createSlice({
  name: 'adminSchools',
  initialState: {
    data: [],
    total: 0,
    params: {
      startDate: null,
      endDate: null,
    },
    selectedSchool: null,
    platformPlans: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedSchool = action.payload.school
        state.platformPlans = action.payload.platformPlans
      })
  }
})

export default appUsersSlice.reducer
