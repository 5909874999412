// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getLessonsGroupsDetailMarks = createAsyncThunk('schoolAdmin/lessons/groups/detail/marks', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminLessonsGroupsMarksList(payload, getState().schoolAdminLessonsGroupsDetailMarks.params)
  return response.data
})

export const schoolAdminLessonsGroupsDetailMarksSlice = createSlice({
  name: 'schoolAdminLessonsGroupsDetailMarks',
  initialState: {
    isLoading: false,
    data: [],
    total: 0,
    mediumMark: null,
    params: {
      itemsPerPage: 10,
      currentPage: 1,
      q: ''
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLessonsGroupsDetailMarks.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getLessonsGroupsDetailMarks.fulfilled, (state, action) => {
      state.data = action.payload.data.items
      state.mediumMark = action.payload.data.mediumMark
      state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
    
  }
})

export const { changeParams } = schoolAdminLessonsGroupsDetailMarksSlice.actions

export default schoolAdminLessonsGroupsDetailMarksSlice.reducer