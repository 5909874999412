// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getLessonsStudentsDocuments = createAsyncThunk('schoolAdmin/lessons/students/detail/documents', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminLessonsStudentsDocumentsList(payload, getState().schoolAdminLessonsStudentsDocuments.params)
  return response.data
})

export const deleteLessonsStudentsDocuments = createAsyncThunk('schoolAdmin/lessons/students/detail/documents/delete', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminLessonsStudentsDocumentsDelete(payload.userId, payload.id)
  await dispatch(getLessonsStudentsDocuments(payload.userId))
  return response.data
})

export const schoolAdminLessonsStudentsDocumentsSlice = createSlice({
  name: 'schoolAdminLessonsStudentsDocuments',
  initialState: {
    isLoading: false,
    data: [],
    total: 0,
    params: {
      itemsPerPage: 10,
      currentPage: 1,
      q: ''
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLessonsStudentsDocuments.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getLessonsStudentsDocuments.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
    
  }
})

export const { changeParams } = schoolAdminLessonsStudentsDocumentsSlice.actions

export default schoolAdminLessonsStudentsDocumentsSlice.reducer