// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getMarathonWithLesson = createAsyncThunk('student/marathons/lesson', async (payload, { dispatch, getState }) => {
  const response = await useApi.studentMarathonsLessonGet(payload.marathonId, payload.lessonId, payload)
  return response.data
})

export const addAnswerApi = createAsyncThunk('student/marathons/addAnswer', async (payload, { dispatch, getState }) => {
  const response = await useApi.studentMarathonsLessonSetAnswer(
    payload.marathonId,
    payload.lessonId,
    {
      exerciseId: payload.exerciseId,
      isMultipleAnswers: payload.isMultipleAnswers,
      answer: payload.answer
    }
  )
  return response.data
})

export const learnLessonApi = createAsyncThunk('student/marathons/learnLesson', async (payload, { dispatch, getState }) => {
  const response = await useApi.studentMarathonsLearnLesson(payload.marathonId, payload.lessonId, {})
  return response.data
})

export const studentClassSlice = createSlice({
  name: 'studentMarathonLesson',
  initialState: {
    selectedSection: null,
    isLoading: false,
    marathon: null,
    myAnswers: {},
    error: null,
    total: 0,
    params: {},
    lesesonSettings: [],
    learningLesson: false
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
    setActiveCourseLessonSection(state, action) {
      state.selectedSection = action.payload
    },
    addAnswer(state, action) {
      if (!action.payload.isMultipleAnswers) {
        state.myAnswers[action.payload.exerciseId] = action.payload.answer
      } else {
        if (state.myAnswers[action.payload.exerciseId]) {
          state.myAnswers[action.payload.exerciseId].push(action.payload.answer)
        } else {
          state.myAnswers[action.payload.exerciseId] = [action.payload.answer]
        }
      }
    },
    clearExerciseAnswersState(state, action) {
      state.myAnswers[action.payload.exerciseId] = undefined
    },
    changeExerciseSetting(state, action) {
      
      const isExerciseExist = state.lesesonSettings.find(
        ls => parseInt(ls.classId) === parseInt(action.payload.classId) &&
          parseInt(ls.lessonId) === parseInt(action.payload.lessonId) &&
          parseInt(ls.exerciseId) === parseInt(action.payload.exerciseId) &&
          ls.key === action.payload.params.key
      )

      if (isExerciseExist) {
        state.lesesonSettings = state.lesesonSettings.map((ls) => {
          if (
            parseInt(ls.classId) === parseInt(action.payload.classId) &&
            parseInt(ls.lessonId) === parseInt(action.payload.lessonId) &&
            parseInt(ls.exerciseId) === parseInt(action.payload.exerciseId) &&
            ls.key === action.payload.params.key
          ) {
            ls.value = parseInt(action.payload.params.value)
          }

          return ls
        })
      } else {
        state.lesesonSettings = [
          ...state.lesesonSettings,
          {
            classId: action.payload.classId,
            lessonId: action.payload.lessonId,
            exerciseId: action.payload.exerciseId,
            key: action.payload.params.key,
            value: action.payload.params.value
          }
        ]
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMarathonWithLesson.pending, (state) => {
      state.isLoading = true
      state.error = null
    })
    builder.addCase(getMarathonWithLesson.fulfilled, (state, action) => {
      state.marathon = action.payload.data.marathon
      state.lesesonSettings = action.payload.data.lesesonSettings
      state.myAnswers = action.payload.data.answers
      // state.error = !action.payload.data.canAccessToClass ? 'На жаль доступ до цього класу обмежений' : null

      if (state.marathon) {
        state.marathon.courseLesson.sections = state.marathon.courseLesson.sections.sort((a, b) => parseInt(a.position) - parseInt(b.position))

        if (!state.selectedSection) {
          state.selectedSection = state.marathon.courseLesson.sections.length > 0 ? state.marathon.courseLesson.sections[0].id : null
        } else {
          const isSectionExist = state.marathon.courseLesson.sections.length > 0 && state.marathon.courseLesson.sections.find(s => s.id === state.selectedSection) !== undefined

          if (!isSectionExist) {
            state.selectedSection = state.marathon.courseLesson.sections.length > 0 ? state.marathon.courseLesson.sections[0].id : null
          }
        }
      }
      
      state.isLoading = false
    })
    builder.addCase(getMarathonWithLesson.rejected, (state, action) => {
      state.error = 'На жаль доступ до цього уроку обмежений'
      state.isLoading = false
    })

    builder.addCase(learnLessonApi.pending, (state, action) => {
      state.learningLesson = true
    })
    builder.addCase(learnLessonApi.fulfilled, (state, action) => {
      state.learningLesson = false

      if (action.payload.data.nextLesson) {
        window.location.href = `/marathons/${state.marathon.id}/lessons/${nextLesson.id}?course=${nextLesson.courseId}`
      } else {
        window.location.href = `/marathons/${state.marathon.id}`
      }
    })
    builder.addCase(learnLessonApi.rejected, (state, action) => {
      state.learningLesson = false
    })
  }
})

export const { changeParams, setActiveCourseLessonSection, addAnswer, changeExerciseSetting, clearExerciseAnswersState } = studentClassSlice.actions

export default studentClassSlice.reducer