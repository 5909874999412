// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import useApi from '@src/api'

export const getLessonsTeacherDetailFeedbacks = createAsyncThunk('schoolAdmin/lessons/teacher/detail/feedbacks', async (payload, { dispatch, getState }) => {
  const response = await useApi.schoolAdminLessonsTeachersFeedbacks(payload, getState().schoolAdminLessonsTeacherDetailFeedbacks.params)
  return response.data
})



export const schoolAdminLessonsTeacherDetailFeedbacksSlice = createSlice({
  name: 'schoolAdminLessonsTeacherDetailFeedbacks',
  initialState: {
    isLoading: false,
    data: [],
    total: 0,
    params: {
      itemsPerPage: 10,
      currentPage: 1,
      q: ''
    },
  },
  reducers: {
    changeParams(state, action) {
      state.params = {...state.params, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLessonsTeacherDetailFeedbacks.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getLessonsTeacherDetailFeedbacks.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload._meta.pagination.total
      state.isLoading = false
    })
    
  }
})

export const { changeParams } = schoolAdminLessonsTeacherDetailFeedbacksSlice.actions

export default schoolAdminLessonsTeacherDetailFeedbacksSlice.reducer